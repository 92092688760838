<template>
  <div class="ma-9">
    <HnrButton
        :text="'Thêm mới deal sốc'"
        color="primary"
        width="200px"
        @hnrClickEvent="addBook"
    />
    {{dealCourses}}
    <v-data-table
        :headers="headers"
        :items="ListCoursesDeal"
    >
<!--      <template v-slot:item.url="{ item }">-->
<!--        <v-img-->
<!--            :src="item.url"-->
<!--            width="200px"-->
<!--        ></v-img>-->
<!--      </template>-->
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="getDetail(item.slug,item.id)">
          mdi-lead-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item.id)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {
  GET_LIST_DEAL_COURSE,DELETE_DEAL_COURSE
} from "@/store/dealCourses.module";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

export default {
  components: {
    HnrButton: () => import("@/components/elements/hnr-button")
  },
  //list news
  data() {
    return {
      headers: [
        {text: "Tên khóa học", value: "title"},
        {text: "Slug", value: "slug"},
        {text: "Hành động", value: "actions", sortable: false}
      ]
    };
  },

  async created() {
    await this.$store.dispatch(GET_LIST_DEAL_COURSE);
    await this.$store.dispatch(SET_BREADCRUMB, [{ title: "deal sốc",titledad:'deal sốc' }]);
  },

  computed: {
    ...mapGetters({
      ListCoursesDeal: 'ListCoursesDeal',
    }),
  },
  methods: {
    addBook() {
      this.$router.push('/deal/create')
    },
    getDetail(slug,id) {
      this.$router.push({
        path: `/deal/detail`,
        query:{
          slug : slug,
          id : id
        }
      })
    },
    async deleteItem(id) {
      if (confirm("Bạn muốn xóa deal sốc?")) {
        await this.$store.dispatch(DELETE_DEAL_COURSE,{id : id} ) .then(data =>{
          if (data.data.status){
            this.$toasted.success('Xóa thành công', {
              position: "top-right",
              theme: "toasted-primary",
              duration : 1000
            });
            this.$store.dispatch(GET_LIST_DEAL_COURSE);
          }
        });
      }
    },
    formatPrice(price){
      let data_price = parseFloat(price)
      return new Intl.NumberFormat('de-DE').format(data_price)
    },
  }
};
</script>